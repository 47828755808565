<template>
  <UiTransition
    name="slide-in"
    appear
    @after-enter="afterDialogEnter"
    @before-leave="beforeDialogLeave"
    @after-leave="afterDialogLeave"
  >
    <dialog
      v-if="props.open"
      ref="dialogElement"
      data-el="ui-modal"
      data-test="ui-modal"
      :class="[
        dialogData.classes.dialogElement,
        {
          'max-w-[85rem]': props.size === Size.DEFAULT,
          'max-w-[90vw] rounded-xl': props.size === Size.TOOLTIP,
          'max-w-xl': props.size === Size.XSMALL,
          'max-w-3xl': props.size === Size.SMALL,
          'max-w-4xl': props.size === Size.MEDIUM,
          'max-w-7xl': props.size === Size.BIG,
        },
      ]"
      v-bind="$attrs"
      :inline="props.inline"
      @keydown.esc="handleClose"
      @click="handleDialogClick"
      @cancel.prevent=""
    >
      <template v-if="showNotificationBar">
        <UiNotificationBar
          v-for="messsage in toastMessages"
          :id="messsage.id"
          :key="messsage.id"
          :message="messsage.message"
          :type="messsage.type"
          :closable="true"
          :timeout="messsage.timeout"
          class="mb-2 transition-all"
          @click:close="closeNotification(messsage.id)"
        />
      </template>

      <slot
        name="header"
        v-bind="{
          handleClose,
          messagesCount: toastMessages.length,
          headerClass: dialogData.classes.header,
          notificationStyles: notificationType.styles.header,
        }"
      >
        <header
          :class="[
            dialogData.classes.header,
            notificationType.styles.header,
            ...props.headerClass,
          ]"
        >
          <UiIcon
            v-if="notificationType.icon.name"
            v-bind="notificationType.icon"
            :class="[notificationType.styles.icon]"
          />
          <UiButton
            v-if="props.closable"
            :class="[
              dialogData.classes.closeButton,
              notificationType.styles.closeButton,
            ]"
            variant="icon"
            @click="handleClose"
          >
            <UiIcon
              name="close"
              class="transition-colors group-hover:text-blue-150"
              :width="16"
              :height="16"
            />
          </UiButton>
        </header>
      </slot>
      <div
        :class="[dialogData.classes.content, ...props.contentClass]"
        data-el="ui-modal-content"
      >
        <slot v-bind="{ closable: props.closable, handleClose }" />
      </div>
      <footer
        v-if="$slots.footer"
        :class="dialogData.classes.footer"
      >
        <slot
          name="footer"
          v-bind="{ closable: props.closable, handleClose }"
        />
      </footer>
    </dialog>
  </UiTransition>
</template>

<script lang="ts" setup>
import { computed, onUnmounted, ref, watchEffect } from 'vue'
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap'
import { useNotification } from '@base/composables/useNotifications'
import type { NotificationComponentType } from '@base/stores/notifications.types'
import UiButton from '../UiButton/UiButton.vue'
import UiNotificationBar from '../UiNotificationBar/UiNotificationBar.vue'
import UiIcon from '../UiIcon/UiIcon.vue'
import UiTransition from '../UiTransition/UiTransition.vue'
import { NotificationTypeEnum, Size } from './UiModal.types'
import type { UiModalProps } from './UiModal.types'

const props = withDefaults(defineProps<UiModalProps>(), {
  inline: false,
  type: 'default',
  size: 'default',
  closable: true,
  showNotificationBar: true,
  contentClass: () => [],
  headerClass: () => [],
})
const emit = defineEmits<{
  (e: 'update:open', value: boolean): void
  (e: 'close'): void
}>()
const _componentType: NotificationComponentType
  = 'toast' as NotificationComponentType
const { toastMessages, closeNotification } = useNotification(_componentType)

const dialogElement = ref<InstanceType<any> | undefined>(null)
const {
  hasFocus: isFocusTrapActivated,
  activate: activateFocusTrap,
  deactivate: deactivateFocusTrap,
} = useFocusTrap(dialogElement)

const notificationType = computed(() => {
  switch (props.type) {
    case NotificationTypeEnum.SUCCESS:
      return {
        styles: {
          header: `bg-notification-success`,
          closeButton: `text-white`,
          icon: `text-white`,
        },
        icon: {
          name: 'check',
          width: 16,
          height: 12.5,
        },
      }
    case NotificationTypeEnum.INFO:
      return {
        styles: {
          header: `bg-notification-info`,
          closeButton: `text-white`,
          icon: `text-white`,
        },
        icon: {
          name: 'info-outline',
          width: 24,
          height: 24,
        },
      }
    case NotificationTypeEnum.ERROR:
      return {
        styles: {
          header: `bg-notification-error`,
          closeButton: `text-white`,
          icon: `text-white`,
        },
        icon: {
          name: 'warning-outline',
          width: 20,
          height: 20,
        },
      }
    default:
      return {
        styles: {
          header: `bg-notification-default`,
          closeButton: `text-primary`,
          icon: ``,
        },
        icon: {
          name: '',
          width: 0,
          height: 0,
        },
      }
  }
})
const dialogData = computed(() => {
  return {
    classes: {
      dialogElement: [
        'fixed z-modal p-0 inset-0 m-auto w-full z-10 my-4 flex flex-col md:rounded-xl border border-none bg-white p-0 backdrop:bg-primary backdrop:bg-opacity-70 pb-4 ',
        {
          'h-full max-w-[100vw] max-h-svh !m-0': props.size === Size.FULL,
          'sm:pb-10': props.size !== Size.SMALL && props.size !== Size.MEDIUM,
        },
      ],
      header: 'w-full flex items-center relative right-0 p-5 bg-[inherit]',
      closeButton:
        'group left-1.5 ml-auto !block text-primary motion-reduce:transition-none',
      content:
        'custom-scrollbar grow sm:pr-11 sm:mr-5 pl-4 pr-3.5 sm:pl-16 mt-1',
      footer: 'pl-4 pr-6 py-4 sm:pt-9 sm:py-16 sm:px-20',
    },
  }
})
function showModal() {
  props.inline
    ? dialogElement.value.show() // without overlay
    : dialogElement.value.showModal() // with overlay
}
function showCloseModal() {
  if (!dialogElement?.value) {
    return
  }
  props.open ? showModal() : dialogElement.value.close()
}

function handleClose() {
  if (!props.closable) {
    return
  }

  dialogElement.value.close()
  emit('update:open', false)
  emit('close')
}

function handleDialogClick(event: Event) {
  if (event.target !== dialogElement.value) {
    return
  }
  handleClose()
}

function afterDialogEnter() {
  scrollLock()
  dialogElement.value?.focus()
  activateFocusTrap()
}
function beforeDialogLeave() {
  if (isFocusTrapActivated) {
    deactivateFocusTrap()
  }
}
function afterDialogLeave() {
  clearAllBodyScrollLocks()
}

function scrollLock() {
  if (dialogElement?.value) {
    disableBodyScroll(dialogElement?.value, {
      reserveScrollBarGap: true,
    })
  }
}
onUnmounted(() => {
  clearAllBodyScrollLocks()
  if (isFocusTrapActivated) {
    deactivateFocusTrap()
  }
})
watchEffect(() => {
  showCloseModal()
})

defineExpose({
  dialogElement,
  deactivateFocusTrap,
  activateFocusTrap,
})
</script>

<style scoped lang="postcss">
/* Fixes issue with non existing scrollbar on iPads */
@supports (scrollbar-width: thin) and (scrollbar-color: initial) {
  [data-el='ui-modal-content'] {
    @apply overflow-y-auto;
  }
}
</style>
