export enum Size {
  DEFAULT = 'default',
  TOOLTIP = 'tooltip',
  XSMALL = 'xsmall',
  SMALL = 'small',
  MEDIUM = 'medium',
  BIG = 'big',
  FULL = 'full',
}

export type SizeType = `${Size}`

export enum NotificationTypeEnum {
  DEFAULT = 'default',
  SUCCESS = 'success',
  INFO = 'info',
  ERROR = 'error',
}

export type ModalType = `${NotificationTypeEnum}`

export interface UiModalProps {
  open: boolean
  inline?: boolean
  type?: ModalType
  size?: SizeType
  closable?: boolean
  showNotificationBar?: boolean
  contentClass?: string[]
  headerClass?: string[]
}
